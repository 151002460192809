import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../layouts'
import Helmet from 'react-helmet'
import { logo } from 'images/over-ons'

const PrivacyPage = ({location}) => (
  <Layout location={location}>
    <main className="privacy over-ons">

      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12">
              <img src={logo} alt="Employes logo large" className="margin-s-bottom" />
              <p className="streamer large wide center">Salarisadministratie en HR. Alles online, alles op 1 plek.</p>
            </div>
          </div>
        </div>
      </header>

      <section className="mission padding-xl">
        <div className="container-md">
          <div className="grid yg center">
            <div className="col-12 margin-xl-bottom">

                <h2>Privacy policy</h2>
                <p className="margin-m-bottom">Wij zijn Employes B.V.. Wij respecteren jouw privacy en persoonlijke levenssfeer, maar soms hebben we persoonlijke gegevens van jou nodig. In deze verklaring lees je welke gegevens wij gebruiken en hoe wij deze gegevens opslaan, beschermen en verwerken. Deze privacy policy is van toepassing op onze website www.employes.nl (de "Website"), onze online applicatie (de "App") en de diensten die wij aanbieden (de "Diensten"). Wij behandelen jouw persoonlijke gegevens conform de Algemene Verordening Gegevensbescherming (de "Relevante Wetgeving")</p>

                <h3>1. Persoonsgegevens</h3>
                <p className="margin-m-bottom">Om onze Website, App en Diensten aan te kunnen bieden, verwerken wij persoonsgegevens. Met het begrip "persoonsgegeven" wordt informatie bedoeld waarmee je als persoon geïdentificeerd kan worden. Deze definitie is in overeenstemming met de Relevante Wetgeving.</p>

                <h3>2. Jouw toestemming</h3>
                <p className="margin-m-bottom">We kunnen jouw gegevens op verschillende momenten bewaren, zoals wanneer je onze Website bezoekt, een account aanmaakt op onze Website of App, gebruik maakt van onze Diensten of als je contact met ons opneemt. Wij mogen jouw gegevens verwerken, omdat we jouw toestemming vragen via deze privacy policy. Bovendien hebben we jouw gegevens nodig om de overeenkomst tussen jou en ons uit te voeren. Als je ervoor kiest om je gegevens niet te delen, dan Als je er voor kiest om je gegevens niet te delen, dan kunnen wij niet onze diensten aan je leveren.<br/><br/>
                Verder zullen we jouw gegevens niet zonder jouw toestemming verwerken, tenzij we daartoe wettelijk verplicht zijn.</p>


                <h3>3. Welke gegevens verzamelen wij en wat doen we ermee?</h3>
                <p>Om gebruik te maken van onze Website, App en Diensten hebben wij de volgende persoonsgegevens van jou nodig:<br/></p>
                <ul>
                  <li>Je naam</li>
                  <li>Je geboortedatum</li>
                  <li>Je geslacht</li>
                  <li>Je adresgegevens</li>
                  <li>Je woonplaats</li>
                  <li>Je telefoonnummer</li>
                  <li>Je e-mailadres</li>
                  <li>Je IP-adres</li>
                  <li>Je burgerservicenummer en/of het documentnummer van je paspoort of ID</li>
                  <li>Je betaalgegevens</li>
                  <li>alle benodigde gegevens voor een correcte salarisverwerking</li>
                </ul>
                <p>We verzamelen ook wat informatie van jou die niet persoonlijk is als je onze Website of App gebruikt, zoals:<br/></p>

                <ul>
                  <li>De naam van de browser die je gebruikt</li>
                  <li>Het besturingssysteem dat je gebruikt</li>
                  <li>De service provider waarmee jij internettoegang hebt</li>
                  <li>De bezocht pagina's op onze website en het gedrag op de website.</li>
                </ul>

                <strong>Login</strong>
                <p className="margin-m-bottom">Op je account slaan we de volgende gegevens op: gebruikersnaam, wachtwoord, naam en telefoonnummer. Zo hoef je deze gegevens niet opnieuw in te voeren.</p>

                <strong>Social media</strong>
                <p className="margin-m-bottom">Als je social media functies gebruikt op onze site, is het mogelijk dat de leverancier van social media ons (volgens zijn beleid) informatie stuurt. Het kan bijvoorbeeld gaan om je naam, profielafbeelding, geslacht, vriendenlijsten en andere informatie die je ter beschikking hebt gesteld. Wil je dit niet? Verander dan de privacy-instellingen op je social media-account. Je kunt ook een ad-blocker gebruiken.</p>

                <p className="margin-m-bottom">Als je op een webpagina terecht komt die social media invoegtoepassingen gebruikt, stuurt je browser bepaalde informatie naar de aanbieder van jouw social media-account. Wij hebben geen zeggenschap over deze gegevensuitwisseling.</p>

                <strong>Nieuwsbrief</strong>
                <p className="margin-m-bottom">Employes B.V. biedt nieuwsbrieven aan. Zo blijf je op de hoogte van nieuws op het gebied van onze software of andere zaken op het vlak van salarisadministratie. Bij iedere communicatie langs deze weg wordt een afmeldmogelijkheid opgenomen.
                Wij zullen jouw gegevens alleen voor bovenstaande doelen gebruiken, of voor een doel dat hier nauw mee samenhangt. Zo worden jouw gegevens dus nooit op een onverwachtse manier door ons gebruikt.</p>

                <h3>4. Geautomatiseerde besluitvorming</h3>
                <p className="margin-m-bottom">Op basis van jouw gegevens kunnen wij bepaalde besluiten automatisch nemen. Dit doen wij op basis van de door jouwe ingevoerde gegevens. Een dergelijk besluit kan voor jou als gevolg hebben dat je emails of andere notificaties kunt ontvangen. Het is belangrijk dat wij zulke besluiten kunnen nemen, om voor een correcte salarisadministratie te zorgen.</p>


                <h3>5. Beveiliging</h3>
                <p>We doen er alles aan om jouw persoonlijke gegevens te beschermen tegen verlies, vernietiging, gebruik, wijziging of verspreiding van jouw persoonlijke gegevens door onbevoegden. Hierdoor kunnen degenen die niets met jouw gegevens te maken hebben, er niet bij. Wij doen dit door middel van de volgende maatregelen:<br/></p>
                <ul>
                  <li>Encryptie (versleuteling) van digitale bestanden die persoonsgegevens bevatten</li>
                  <li>Beveiliging van netwerkverbindingen met Secure Socket Layer (SSL), of een vergelijkbare technologie</li>
                  <li>De toegang tot de gegevens is beperkt tot de personen die de gegevens nodig hebben</li>
                  <li>Geheimhoudingsverklaringen van werknemers.</li>
                  <li>Back-ups van de gegevens.</li>
                </ul>

              <div className="question margin-l-bottom">
                <h3>6. Bewaartermijn</h3>
                <p>Wij zullen jouw persoonlijke gegevens niet langer bewaren dan de wettelijke termijnen op het vlak van salarisadministratie.</p>
              </div>

              <div className="question margin-l-bottom">
                <h3>7. Met wie delen wij jouw persoonlijke gegevens?</h3>

                <p>Het komt voor dat andere partijen jouw gegevens van ons krijgen. Wij komen met deze partijen in een verwerkersovereenkomst overeen dat zij zorgvuldig met jouw gegevens omgaan en zij krijgen alleen de gegevens die noodzakelijk zijn om hun dienst te verlenen. Deze partijen gebruiken jouw gegevens alleen in overeenstemming met de instructies die wij geven en niet voor eigen doeleinden.</p>
                <br/><br/>

                <strong>Verwerkers</strong><br/>
                <p className="margin-m-bottom">Wij kunnen jouw gegevens doorgeven aan: Segment, Intercom, AWS, Hubspot, Google, Mixpanel. Zij zullen zorg dragen voor de opslag van de gegevens en het correct verwerken van de gegevens. Deze partijen zijn "verwerkers" in de zin van de Relevante Wetgeving.</p>


                <strong>Derden</strong>
                <p>Soms delen we jouw gegevens met andere partijen, die geen verwerker zijn. Met deze partijen spreken wij af dat ze jouw gegevens zorgvuldig behandelen. Zij zullen de gegevens alleen gebruiken in overeenstemming met het doel waarvoor wij de gegevens van jou hebben gekregen. Op het moment dat we werkelijk de salarisadministratie voor je doen, geven we je gegevens ook door aan de belastingdienst en eventuele pensioenuitvoerders.</p>
              </div>

              <div className="question margin-l-bottom">
                <h3>8. Doorgifte</h3>
                <p className="margin-m-bottom">Wij zullen jouw gegevens alleen binnen de Europese Unie verwerken. Buiten de Europese Unie verwerken wij alleen jouw gegevens, als dat land een passend beschermingsniveau biedt voor jouw gegevens.<br/><br/>
                Zonder jouw toestemming zullen we jouw gegevens nooit naar andere landen of aan andere partijen doorgeven dan die hierboven genoemd zijn.</p>
              </div>

              <div className="question margin-l-bottom">
                <h3>9. Links</h3>
                <p className="margin-m-bottom">Op onze Website en in onze App kunnen links naar andere websites staan. Wij zijn niet verantwoordelijk voor de inhoud of de privacy bescherming van deze websites. We adviseren je dan ook om altijd de privacy policy van de betreffende website te lezen</p>
              </div>


              <div className="question margin-l-bottom">
                <h3>10.Cookies</h3>
                <p>Een cookie is een klein tekstbestand dat via de server van een website naar de browser wordt gestuurd. De browser slaat het bestandje vervolgens op in je apparaat. Je apparaat krijgt een uniek nummer, waarmee onze site het apparaat later weer herkent.

                  <br/><br/>We kunnen cookies gebruiken om je ervaring op onze Website te verbeteren. Cookies zorgen er daarnaast o.a. voor dat de Website snel is, je onze Website veilig kan bezoeken en wij fouten op onze Website kunnen opsporen.

                    <br/><br/>Je kunt cookies altijd zelf via de browserinstellingen verwijderen of uitschakelen. Er worden dan geen cookies meer opgeslagen als je onze Website bezoekt. Maar let wel op: zonder cookies werkt onze Website mogelijk minder goed. Voor meer informatie kun je ons cookiestatement nalezen: https://employes.nl/cookieverklaring.
                    </p>
              </div>

              <div className="question margin-l-bottom">
                <h3>11. Wijziging van de privacy policy</h3>
                <p className="margin-m-bottom">Wij kunnen deze privacy policy aanpassen. Als wij de privacy policy in belangrijke mate veranderen zullen wij een notificatie op onze Website en in onze App plaatsen samen met de nieuwe privacy policy. Wij zullen gebruikers die met hun e-mailadres bij ons geregistreerd staan op de hoogte brengen bij wijzigingen. Als je niet geregistreerd staat als gebruiker raden wij je aan de Website en deze verklaring regelmatig te raadplegen.</p>
              </div>

              <div className="question margin-l-bottom">
                <h3>12. Jouw rechten</h3>
                <p>De gegevens die we van jou verzamelen zijn persoonlijk. Je hebt daarom de volgende rechten:<br/></p>
                <ul>
                  <li>Je mag ons vragen om inzage in jouw gegevens;</li>
                  <li>Je mag ons vragen om correctie, beperking of wissing van jouw gegevens. In geval van fraude, wanbetaling of andere onrechtmatige handelingen kunnen wij enkele gegevens van jou bewaren in een register of op een zwarte lijst;</li>
                  <li>Je mag ons vragen om een kopie van jouw gegevens. Wij kunnen deze kopie - op jouw verzoek - ook aan een andere partij doorgeven, zodat je dit zelf niet meer hoef te doen;</li>
                  <li>Je kunt bezwaar maken tegen de verwerking van jouw gegevens;</li>
                  <li>Je kunt een klacht indienen bij de Autoriteit Persoonsgegevens, als je denkt dat wij jouw gegevens onrechtmatig verwerken;</li>
                  <li>Je mag jouw toestemming om jouw gegevens te verwerken altijd weer intrekken. Vanaf het moment dat je de toestemming intrekt mogen we dan geen gegevens meer van jou verwerken.</li>
                </ul>
                <p className="margin-m-bottom">Bij andere vragen of opmerkingen over onze privacy policy kun je contact met ons opnemen via onderstaande gegevens.</p>

              </div>

              <div className="question margin-l-bottom">
                <h3>Contact</h3>
                <p >
                  <strong>Employes B.V.</strong><br/>
                  Burgerweeshuispad 101<br/>
                  1076ER te Amsterdam<br/>
                  KvK-nummer: 64254615<br/>
                  info@employes.nl
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default PrivacyPage
